<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
  <a-layout
    class="layout-default layout-sign-in"
    id="layout-default"
    :class="[layoutClass]"
  >
    <DefaultHeader></DefaultHeader>

    <a-layout-content>
      <div class="sign-in">
        <a-row
          type="flex"
          :gutter="[24, 24]"
          justify="space-around"
          align="middle"
        >
          <!-- Sign In Form Column -->
          <a-col
            :span="24"
            :md="12"
            :lg="{ span: 12, offset: 0 }"
            :xl="{ span: 6, offset: 2 }"
            class="col-form"
          >
            <h1 class="mb-15">Sign In</h1>
            <h5 class="font-regular text-muted">
              Enter your email and password to sign in
            </h5>

            <!-- Sign In Form -->
            <a-form
              layout="vertical"
              id="components-form-demo-normal-login"
              :form="form"
              class="login-form"
              @submit="handleSubmit"
              :hideRequiredMark="true"
            >
              <a-form-item class="mb-10" label="Email" :colon="false">
                <a-input
                  v-model:value="form.mail"
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        { required: true, message: 'Please input your email!' },
                      ],
                    },
                  ]"
                  placeholder="Email"
                />
              </a-form-item>
              <a-form-item class="mb-5" label="Password" :colon="false">
                <a-input
                  v-model:value="form.password"
                  v-decorator="[
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ],
                    },
                  ]"
                  type="password"
                  placeholder="Password"
                />
              </a-form-item>
              <a-form-item class="mb-10">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <a-switch v-model:checked="rememberMe" /> Remember Me
                  </div>
                  <a-button
                    type="link"
                    class="guest-btn"
                    style="margin-left: auto"
                    ><router-link to="/">Guest Login</router-link></a-button
                  >
                </div>
              </a-form-item>

              <a-form-item>
                <a-button
                  type="primary"
                  block
                  html-type="submit"
                  class="login-form-button"
                >
                  SIGN IN
                </a-button>
              </a-form-item>
            </a-form>
            <!-- / Sign In Form -->

            <p class="font-semibold text-muted">
              If you forgot your password?
              <router-link to="/user/reset" class="font-bold text-dark"
                >Reset</router-link
              >
            </p>
          </a-col>
          <!-- / Sign In Form Column -->

          <!-- Sign In Image Column -->
          <a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
            <img src="images/img-signin.png" alt="" />
          </a-col>
          <!-- Sign In Image Column -->
        </a-row>
      </div>
    </a-layout-content>

    <DefaultFooter></DefaultFooter>
  </a-layout>
</template>
<script setup>
import DefaultHeader from "@/components/Headers/DefaultHeader";
import DefaultFooter from "@/components/Footers/DefaultFooter";
import { ref } from "vue";
// import { useStore } from "vuex";
import user from "@/api/modules/user";
import { to } from "@/utils/utils";

import { message } from "ant-design-vue";
// const store = useStore();
const rememberMe = ref(true);
const form = ref({
  mail: "",
  password: "",
  type: 0,
});

const handleSubmit = async () => {
  // e.preventDefault();
  // this.form.validateFields((err, values) => {
  // 	if (!err) {
  // 		console.log('Received values of form: ', values);
  // 	}
  // });
  const [err, res] = await to(user.login(form.value));

  if (err) {
    message.error(res.message);
    return;
  }
  console.log(res);
  if (res.code == 200) {
    localStorage.user = JSON.stringify(res.data);

    window.location.href = "/";
  } else {
    message.error(res.message);
  }
};
</script>

<style lang="scss">
.layout-sign-in {
  .ant-layout-content {
    overflow: auto;
  }
}
.guest-btn {
  box-shadow: none !important;
  // text-align: right;
  display: inline-block;
}
</style>
